import React from "react";
import ArticleListing from "./Article";

function Jewelry(){
    return(
        <div>
            <br /><br />
            <section className="text-gray-600 body-font">
            <h1 className="flex justify-center items-center px-auto text-xl font-bold py-6 dark:text-sky-700">Jewelry</h1>
                <ArticleListing category={"jewelry"}></ArticleListing>
            </section>
        </div>
    )
}

export default Jewelry;