import { BrowserRouter , Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";


/*import Dress from "./components/Dress";
import Jeans from "./components/Jeans";
import Blouse from "./components/Blouse";
import Coat from "./components/Coat";
import Swimsuit from "./components/Swimsuit";
import Bra from "./components/Bra";
import Jumper from "./components/Jumper";
import Scarf from "./components/Scarf";
import Shorts from "./components/Shorts";
import Skirt from "./components/Skirt";
import Suit from "./components/Suit";
import Boots from "./components/Boots";
import Gloves from "./components/Gloves";
import Leggings from "./components/Leggings";
import Pajamas from "./components/Pajamas";
import Socks from "./components/Socks";
import Vest from "./components/Vest";
import Activewear from "./components/Activewear";
import Caftan from "./components/Caftan";
import Cap from "./components/Cap";*/

import News from "./components/News";
import Clothing from "./components/Clothing";
import Shoes from "./components/Shoes";
import Bags from "./components/Bags";
import Jewelry from "./components/Jewelry";
import Watches from "./components/Watches";
import Skincare from "./components/Skincare";

import ArticleView from "./components/ArticleView";
import NewView from "./components/NewsView";
import NotFound from "./components/NotFound";
import Disclaimer from "./components/Disclaimers";
import Horoscope from "./components/Horoscope";
import About from "./components/About";



const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path = "/" element = { <Layout></Layout> }>
            <Route index element = { <Home></Home> }></Route>

{/**
             <Route path = "/dress" element = { <Dress/> } ></Route>
            <Route path = "/jeans" element = { <Jeans/> } ></Route>
            <Route path = "/blouse" element = { <Blouse/> } ></Route>
            <Route path = "/coat" element = { <Coat/> } ></Route>
            <Route path = "/swimsuit" element = { <Swimsuit/> } ></Route>
            <Route path = "/bra" element = { <Bra/> } ></Route>
            <Route path = "/jumper" element = { <Jumper/> } ></Route>
            <Route path = "/scarf" element = { <Scarf/> } ></Route>
            <Route path = "/shorts" element = { <Shorts/> } ></Route>
            <Route path = "/skirt" element = { <Skirt/> } ></Route>
            <Route path = "/suit" element = { <Suit/> } ></Route>
            <Route path = "/boots" element = { <Boots/> } ></Route>
            <Route path = "/gloves" element = { <Gloves/> } ></Route>
            <Route path = "/leggings" element = { <Leggings/> } ></Route>
            <Route path = "/pajamas" element = { <Pajamas/> } ></Route>
            <Route path = "/socks" element = { <Socks/> } ></Route>
            <Route path = "/vest" element = { <Vest/> } ></Route>
            <Route path = "/activewear" element = { <Activewear/> } ></Route>
            <Route path = "/caftan" element = { <Caftan/> } ></Route>
            <Route path = "/cap" element = { <Cap/> } ></Route>
 */}

            <Route path = "/news" element = { <News/> } ></Route>
            <Route path = "/clothing" element = { <Clothing/> } ></Route>
            <Route path = "/shoes" element = { <Shoes/> } ></Route>
            <Route path = "/bags" element = { <Bags/> } ></Route>
            <Route path = "/jewelry" element = { <Jewelry/> } ></Route>
            <Route path = "/watches" element = { <Watches/> } ></Route>
            <Route path = "/skincare" element = { <Skincare/> } ></Route>

            <Route path = "/read/:id"  element = { <ArticleView></ArticleView> } ></Route>
            <Route path = "/read/news/:id"  element = { <NewView></NewView> } ></Route>

            {/*<Route path = "/disclaimer" element = { <Disclaimer></Disclaimer> } ></Route>*/}
            <Route path = "/about" element = { <About></About> } ></Route>
            <Route path = "/horoscope" element = { <Horoscope></Horoscope> } ></Route>
            <Route path='*' element={<NotFound />}></Route>
          </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App